


.StyledVariations_s1um12q8{position:relative;width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;margin:0 0 16px 0;}.StyledVariations_s1um12q8 > .StyledFieldWrapper_s18ijdjh{width:100%;}
.VariationsSelectedName_v1k0k9kp.Paragraph12_p12g4ckv.TypographyBase_t102nrcx{margin-bottom:8px;}.VariationsSelectedName_v1k0k9kp.Paragraph12_p12g4ckv.TypographyBase_t102nrcx > .Span_s1a8gko7{color:#7A7A7A;}
.VariationsBox_v5nw2fo{position:relative;overflow:hidden;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;margin:0;border-radius:4px;border:2px solid #F5F5F5;padding:2px 6px 2px 2px;cursor:pointer;-webkit-transition:all .1s cubic-bezier(0.22,0.61,0.36,1);transition:all .1s cubic-bezier(0.22,0.61,0.36,1);}.VariationsBox_v5nw2fo:hover{border-color:#DEDEDE;}.VariationsBox_v5nw2fo > .StyledLinkBase_s1ktnduy{position:absolute;left:0;top:0;padding:0;margin:0;width:100%;height:100%;background-color:transparent;z-index:2;cursor:pointer;font-size:0;}.VariationsBox_v5nw2fo.cssBoxText_c1v7bdol{min-height:48px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding-left:6px;padding-right:6px;}.VariationsBox_v5nw2fo.cssBoxActive_cljx0g4{border-color:#FECC00;}
.VariationsBoxInner_v1fkfi0o,.StyledVariations_s1um12q8 .VariationsBoxInner_v1fkfi0o{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex:1;-ms-flex:1;flex:1;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;}.VariationsBoxInner_v1fkfi0o .StyledEntityImage_sk1fjhn,.StyledVariations_s1um12q8 .VariationsBoxInner_v1fkfi0o .StyledEntityImage_sk1fjhn{height:40px;width:40px;margin:0 10px 0 0;border-radius:50px;background-color:#FFFFFF;}.VariationsBoxInner_v1fkfi0o .StyledEntityImage_sk1fjhn .cssIcon_cc7xf1o,.StyledVariations_s1um12q8 .VariationsBoxInner_v1fkfi0o .StyledEntityImage_sk1fjhn .cssIcon_cc7xf1o{width:32px;height:32px;min-width:32px;min-height:32px;}.VariationsBoxInner_v1fkfi0o .StyledEntityImage_sk1fjhn ~ .Span_s1a8gko7,.StyledVariations_s1um12q8 .VariationsBoxInner_v1fkfi0o .StyledEntityImage_sk1fjhn ~ .Span_s1a8gko7{-webkit-flex:1;-ms-flex:1;flex:1;}.StyledSelectedTitle_skd9w25 .VariationsBoxInner_v1fkfi0o{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.StyledSelectedTitle_skd9w25 .VariationsBoxInner_v1fkfi0o .StyledEntityImage_sk1fjhn{height:16px;width:16px;}.StyledSelectedTitle_skd9w25 .VariationsBoxInner_v1fkfi0o .StyledEntityImage_sk1fjhn .cssIcon_cc7xf1o{width:16px;height:16px;min-width:16px;min-height:16px;}.StyledSelectedTitle_skd9w25 .VariationsBoxInner_v1fkfi0o .StyledEntityImage_sk1fjhn ~ .cssIcon_cc7xf1o{margin-right:10px;}
.VariationsBoxList_vm58y46{position:relative;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;grid-gap:4px;gap:4px;}
.VariationsBoxInner_v1fkfi0o.cssBoxDisabled_c1tm2ne7 .StyledEntityImage_sk1fjhn{border:2px solid #7A7A7A;position:relative;}.VariationsBoxInner_v1fkfi0o.cssBoxDisabled_c1tm2ne7 .StyledEntityImage_sk1fjhn:before{content:"";position:absolute;height:2px;width:100%;background:#7A7A7A;z-index:2;-webkit-transform:rotate(-45deg);-ms-transform:rotate(-45deg);transform:rotate(-45deg);top:50%;margin-top:-1px;left:0;}.VariationsBoxInner_v1fkfi0o.cssBoxDisabled_c1tm2ne7 .StyledEntityImage_sk1fjhn{opacity:0.6;}.VariationsBox_v5nw2fo.cssBoxDisabled_c1tm2ne7{position:relative;background-color:#F5F5F5;border-color:#DEDEDE;color:#7A7A7A;cursor:default;}.VariationsBox_v5nw2fo.cssBoxDisabled_c1tm2ne7:hover{border-color:#DEDEDE;}.VariationsBox_v5nw2fo.cssBoxDisabled_c1tm2ne7:before{content:"";position:absolute;height:2px;width:100%;background:#DEDEDE;z-index:2;-webkit-transform:rotate(-45deg);-ms-transform:rotate(-45deg);transform:rotate(-45deg);top:50%;margin-top:-1px;left:0;}.VariationsBox_v5nw2fo.cssBoxDisabled_c1tm2ne7 .StyledEntityImage_sk1fjhn{opacity:0.6;}
.cssVariationsSelect_c144yqb9 .VariationsBoxInner_v1fkfi0o > .StyledLinkBase_s1ktnduy{display:none;}
